<!--
 * @Author: nice
 * @Date: 2021-11-04 10:53:05
 * @LastEditTime: 2021-12-04 10:25:16
 * @LastEditors: nice
 * @Description: 顶部导航
-->
<template>
  <div class="navbar-wrap">
    <!-- <div class="simulate-status-bar "></div> -->
    <van-nav-bar :title="title" :class="`${background}-navbar`" left-text="返回" left-arrow @click-left="onClickLeft" />
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    title: {
      type: String,
      default: '标题'
    },
    background: {
      type: String,
      default: 'white'
    }
  },
  methods: {
    onClickLeft() {
      // 是否传入了自定义点击事件
      if (this.$listeners.leftClick) {
        this.$emit('leftClick');
        return;
      }
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less">
.navbar-wrap{
   .van-hairline--bottom::after{
    border-bottom-width:0 !important;
  }
}
.simulate-status-bar{
  width:100%;
  height:25px;
  background-color: #4284f3;

}
.blue-navbar {
  height: 46px;
  background-color: #4284f3;
  .van-icon {
    color: #fff;
  }
  .van-nav-bar__text {
    color: #fff;
  }
  .van-nav-bar__title {
    color: #fff;
  }

}
</style>
