/**
 * 全局状态对象
 */
import {getState} from "core-js/modules/web.url-search-params";

class TranscateRst {
  /**
   * 构造自定义全局状态对象
   * @param state 状态：true false
   * @param msg  消息：文本内容
   * @param data 数据体：不包含状态和文本消息的数据结构体
   * @param native http响应的原生数据包
   */
  constructor(state, msg, data, native) {
    this.state = state
    this.msg = msg
    this.data = data
    this.native = native
  }
}

export const util = {
  /**
   * 格式化http返回的状态数据，只有成果与失败，和原因
   * @param data
   */
  getState: data => {
    if (data.code == 9200) {
      return true
    } else {
      return false
    }
  },
  /**
   * 获取http返回的状态数据的msg
   * @param data
   * @returns {string|*|string}
   */
  getMsg: data => {
    return data.msg || '获取错误';
  },
  /**
   * 获取接口数据包主体data
   * @param data
   * @returns {string}
   */
  getData: data => {
    return data.data || '获取错误';
  },
  /**
   * 翻译Api返回的接口数据
   * @param data
   */
  getTranscateRst: data => {
    // console.log(data);
    const state = util.getState(data);
    const msg = util.getMsg(data);
    const tdata = util.getData(data);
    let transcateRst = new TranscateRst(state, msg, tdata, data);
    // console.log('Transcate Http Rst Object：', transcateRst);
    return transcateRst;
  }
}
