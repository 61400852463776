/*
 * @Author: nice
 * @Date: 2021-11-04 11:07:51
 * @LastEditTime: 2021-11-24 19:49:21
 * @LastEditors: nice
 * @Description: 
 */
import NavBar from '@/components/navBar/NavBar.vue';
const navBar = {
  install: (Vue) => {
    Vue.component('NavBar', NavBar);
  }
};
export default navBar;
