/*
 * @Author: master
 * @Date: 2021-11-06 15:22:27
 * @LastEditTime: 2021-11-18 09:18:17
 * @Description: 本地数据存储
 */
import { Base64 } from 'js-base64';

/**
 * 获取LocalStorage信息
 */
export const getLocalStorage = key => {
  try {
    const keyInfo = Base64.encodeURI(key);
    const itemInfo = localStorage.getItem(keyInfo);
    if (itemInfo) {
      const info = Base64.decode(itemInfo);
      return JSON.parse(info);
    } else {
      return '';
    }
  } catch (e) {
    return '';
  }
};
/**
 * 获取所有LocalStorage信息
 */
export const getAllLocalStorage = () => {
  return localStorage;
};
/**
 * 删除所有的本地存储(LocalStorage)
 */
export const deleteAllLocalStorage = () => {
  localStorage.clear();
};
/**
 * 存储LocalStorage信息
 */
export const setLocalStorage = (key, value) => {
  const keyInfo = Base64.encodeURI(key);
  const itemInfo = Base64.encodeURI(JSON.stringify(value));
  localStorage.setItem(keyInfo, itemInfo);
};
/**
 * 删除LocalStorage信息
 */
export const deleteLocalStorage = key => {
  const keyInfo = Base64.encodeURI(key);
  delete localStorage[keyInfo];
};
/**
 * 获取SessionStorage信息
 */
export const getSessionStorage = key => {
  const keyInfo = Base64.encodeURI(key);
  const itemInfo = sessionStorage.getItem(keyInfo);
  if (itemInfo) {
    const info = Base64.decode(itemInfo);
    return JSON.parse(info);
  } else {
    return '';
  }
};
/**
 * 存储SessionStorage信息
 */
export const setSessionStorage = (key, value) => {
  const keyInfo = Base64.encodeURI(key);
  const itemInfo = Base64.encodeURI(JSON.stringify(value));
  sessionStorage.setItem(keyInfo, itemInfo);
};
/**
 * 删除SessionStorage信息
 * @param key
 */
export const deleteSessionStorage = key => {
  const keyInfo = Base64.encodeURI(key);
  delete sessionStorage[keyInfo];
};
/**
 * 删除所有的本地存储(sessionStorage)
 */
export const deleteAllSessionStorage = () => {
  sessionStorage.clear();
};
