/*
 * @Author: master
 * @Date: 2021-10-31 14:35:57
 * @LastEditTime: 2021-12-04 09:55:04
 * @Description: 路由集合
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index.js';
import payRouter from './pay'; // 在线缴费
const routes = [
  ...payRouter,
  {
    path: '*',
    component: () => import('@/components/empty/EmptyRoute.vue')
  },
  // 缴费首页
  {
    path: '/',
    name: 'payHome',
    component: () => import('@/views/pay/PayHome.vue')
  }
  
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

Vue.use(VueRouter);
export default router;
