/*
 * @Author: nice
 * @Date: 2021-11-14 18:27:38
 * @LastEditTime: 2021-12-04 10:01:09
 * @LastEditors: nice
 * @Description: 全局服务
 */

import Vue from 'vue';
import store from '../store/index.js';
import router from '../router/index.js';
import moment from 'moment';
import { deleteLocalStorage } from '@/common/localDataStorage.js';

// 月份
Vue.prototype.$tMonth = function (tiemNum) {
  if (!tiemNum) {
    console.log('请传入时间戳');
    return;
  }
  return moment(tiemNum).format('YYYY-MM');
};
// 日期
Vue.prototype.$tDate = function (tiemNum) {
  if (!tiemNum) {
    console.log('请传入时间戳');
    return;
  }
  return moment(tiemNum).format('YYYY-MM-DD');
};
// 日期时间
Vue.prototype.$tTime = function (tiemNum) {
  if (!tiemNum) {
    console.log('请传入时间戳');
    return;
  }
  return moment(tiemNum).format('YYYY-MM-DD HH:mm:ss');
};
const getCookie = name => {
  let arr;
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');

  if ((arr = document.cookie.match(reg))) {
    return arr[2];
  } else {
    return null;
  }
};

// 删除cookies
const delCookie = name => {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name);
  if (cval != null) {
    document.cookie = name + '=' + cval + ';expires=' + exp.toGMTString();
  }
};
/**
 * 清除本地存储数据
 */
export const clearLocalData = () => {
  deleteLocalStorage('Authorization');
  deleteLocalStorage('userInfo');
  deleteLocalStorage('userRoles');
  deleteLocalStorage('curRoles');
  delCookie('Authorization');
  store.commit('user/removeUseInfo');
};

/**
 * 转换设备数据
 */
export const handleField = field => {
  if (!field) return;
  const fieldObjArr = JSON.parse(field);
  const arr = fieldObjArr.map(item => {
    return `${item.name}(${item.count || 0})`;
  });
  return arr.join(' | ');
};
