/*
 * @Author: nice
 * @Date: 2021-11-12 21:56:55
 * @LastEditTime: 2021-12-04 09:54:31
 * @LastEditors: nice
 * @Description: 线上缴费
 */
const payRouter = [

  // 协议
  {
    path: '/protocol',
    name: 'protocol',
    component: () => import('@/views/pay/Protocol.vue')
  },
  // 账户信息
  {
    path: '/accountInfo',
    name: 'accountInfo',
    component: () => import('@/views/pay/AccountInfo.vue')
  },
  // 账户预存
  {
    path: '/stored',
    name: 'stored',
    component: () => import('@/views/pay/Stored.vue')
  },
  // 立即支付
  {
    path: '/payAtOnce',
    name: 'payAtOnce',
    component: () => import('@/views/pay/PayAtOnce.vue')
  },
  // 缴费记录
  {
    path: '/paymentRecords',
    name: 'paymentRecords',
    component: () => import('@/views/pay/PaymentRecords.vue')
  },
  // 欠费记录
  {
    path: '/arrearsRecords',
    name: 'arrearsRecords',
    component: () => import('@/views/pay/ArrearsRecords.vue')
  },
  // 欠费详情
  {
    path: '/arrearsDetail',
    name: 'arrearsDetail',
    component: () => import('@/views/pay/ArrearsDetail.vue')
  },
  // 欠费立即缴费
  {
    path: '/arrearsPayAtOnce',
    name: 'arrearsPayAtOnce',
    component: () => import('@/views/pay/ArrearsPayAtOnce.vue')
  },
  // 支付中
  {
    path: '/continuePay',
    name: 'continuePay',
    component: () => import('@/views/pay/ContinuePay.vue')
  },
];
export default payRouter;
