/*
 * @Author: master
 * @Date: 2021-10-31 14:35:57
 * @LastEditTime: 2021-11-15 14:22:41
 * @Description:
 */
import Vue from 'vue';
import Vuex from 'vuex';
import user from './user.js'; // 用户信息

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user
  }
});
