<!--
 * @Author: master
 * @Date: 2021-10-31 14:35:57
 * @LastEditTime: 2021-12-03 22:20:20
 * @Description: 
-->
<template>
  <transition :name="transitionName">
    <!-- <keep-alive>
      <router-view />
    </keep-alive> -->
    <router-view />
  </transition>
</template>
<script>
export default {
  data() {
    return {
      transitionName: ''
    };
  },
  watch: {
    // 使用watch 监听$router的变化
    $route(to, from) {
      if (to.meta?.index && from?.meta?.index) {
        // 如果to索引大于from索引,判断为前进状态,反之则为后退状态
        if (to.meta.index < from.meta.index) {
          // 设置动画名称
          this.transitionName = 'slide-right';
        } else {
          this.transitionName = 'slide-left';
        }
      } else {
        this.transitionName = '';
      }
    }
  }
};
</script>
<style lang="less" scoped>
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  width: 100%;
  will-change: transform;
  transition: all 400ms;
  position: absolute;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
