/*
 * @Author: master
 * @Date: 2021-10-31 14:35:57
 * @LastEditTime: 2021-11-16 20:03:07
 * @Description:
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vant from 'vant';
import './index.less';
import './common/business.less';
import 'vant/lib/index.css';
import 'font-awesome/css/font-awesome.min.css';
import '@/common/globalServer.js';
import NavBar from '@/components/navBar/NavBar.js';
import BaseList from '@/components/list/BaseList.js';
import {util} from "@/services/util";
Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(NavBar);
Vue.use(BaseList);
Vue.prototype.util = util
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
