/*
 * @Author: master
 * @Date: 2021-11-15 14:22:36
 * @LastEditTime: 2021-11-16 21:20:43
 * @Description: 用户信息
 */
import { getLocalStorage } from '@/common/localDataStorage.js';
const state = () => ({
  // 用户信息
  info: getLocalStorage('userInfo') || '',
  // 角色数据
  roles: getLocalStorage('userRoles') || [],
  // 当前角色
  curRoles: getLocalStorage('curRoles') || ''
});

// getters
const getters = {
  // 用户信息
  info: state => state?.info || getLocalStorage('userInfo'),
  // 角色数据
  roles: state => (state?.roles.length ? state.roles : getLocalStorage('userRoles')),
  // 当前角色
  curRoles: state => state?.curRoles || getLocalStorage('curRoles')
};

// actions
const actions = {};

// mutations
const mutations = {
  /**
   * 设置用户信息
   */
  setInfo(state, info) {
    state.info = info;
  },
  /**
   * 设置用户权限
   */
  setRoles(state, roles) {
    state.roles = roles;
  },
  /**
   * 设置当前角色
   */
  setCurRoles(state, curRoles) {
    state.curRoles = curRoles;
  },
  /**
   * 删除用户信息
   */
  removeUseInfo(state) {
    state.info = '';
    state.roles = [];
    state.curRoles = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
