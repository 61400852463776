/*
 * @Author: nice
 * @Date: 2021-11-06 21:04:07
 * @LastEditTime: 2021-11-06 21:04:07
 * @LastEditors: nice
 * @Description: 基础分页组件
 */
import BaseList from '@/components/list/BaseList.vue';

const baseList = {
  install: (Vue) => {
    Vue.component('BaseList', BaseList);
  }
};
export default baseList;
