<!--
 * @Author: master
 * @Date: 2021-09-08 10:19:41
 * @LastEditTime: 2021-11-06 21:20:55
 * @Description: 基础列表组件
 * ps:ID如果有重复，会造成空白间距
-->
<template>
  <RecycleScroller
    ref="scroller"
    :items="dataSrouce"
    :item-size="itemHeight"
    style="height: 100%"
    :infinite-scroll-distance="0"
    v-infinite-scroll="handleInfiniteOnLoadDown"
  >
    <template v-slot="{ item, index }">
      <slot name="slotItem" :item="item" :index="index" :curItem="curItem" />
    </template>
    <template #before>
      <span v-if="topLoading && !toNoMore" class="intro">加载中...</span>
      <span v-if="toNoMore" class="intro"> 暂无更多</span>
    </template>
    <template #after>
      <van-loading v-if="loading && !noMore" class="intro" />
      <!-- 暂无更多数据 -->
      <span v-if="noMore" class="intro"> 暂无更多</span>
    </template>
  </RecycleScroller>
</template>
<script>
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { RecycleScroller } from 'vue-virtual-scroller';
import infiniteScroll from 'vue-infinite-scroll';
export default {
  directives: { infiniteScroll },
  props: {
    // 加载状态
    loading: {
      default: false,
      type: Boolean
    },
    // 顶部加载更多
    topLoading: {
      default: false,
      type: Boolean
    },
    // 没有更多
    noMore: {
      default: false,
      type: Boolean
    },
    // 顶部没有更多
    toNoMore: {
      default: false,
      type: Boolean
    },
    // 当前选中子项
    // eslint-disable-next-line vue/require-prop-type-constructor
    curItem: Array | Object | String | undefined,
    // 数据源
    dataSrouce: Array,
    // 每行高度
    itemHeight: {
      type: Number,
      default: 80
    }
  },
  mounted() {
    // 监听滚动条，目的是监听是否到顶
    this.addScrollListener();
  },
  methods: {
    /**
     * 滚动到底部触发
     */
    handleInfiniteOnLoadDown() {
      if (this.loading || this.noMore) {
        return;
      }
      // 加载更多
      this.$emit('handleInfiniteOnLoadDown');
    },
    /**
     * 监听滚动条，目的是监听是否到顶
     */
    topHandleScrill() {
      if (this.topLoading || this.toNoMore) {
        return;
      }
      // 滑动到了顶部
      if (this.$refs?.scroller?.$el?.scrollTop === 0) {
        this.$emit('handleInfiniteOnLoadUp');
      }
    },
    /**
     * 添加滚动条事件监听
     */
    addScrollListener() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs?.scroller?.$el.addEventListener('scroll', this.topHandleScrill, true);
    },
    /**
     * 取消滚动条事件监听
     */
    removeScrollListener() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs?.scroller?.$el.removeEventListener('scroll', this.topHandleScrill, false);
    },
    /**
     * 回到顶部
     */
    scrollToTop() {
      this.$refs.scroller.$el.scrollTop = 0;
    },
    /**
     * 获取距离顶部距离
     */
    getScrollToTop() {
      return this.$refs.scroller.$el.scrollTop;
    }
  },
  destroyed() {
    // 取消滚动条事件监听
    this.removeScrollListener();
  },
  components: {
    RecycleScroller
  }
};
</script>
<style lang="less">
  .intro{
    display: flex;
    width: 100%;
    font-size: 15px;
    color: rgba(0,0,0,0.5);
    justify-content: center;
    height:30px;
  }
</style>
